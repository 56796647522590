
export default {
  props: {
    children: { type: Object, default: () => {} },
    primaryBanner: { type: Object, default: () => {} },
    primaryBannerLink: { type: Object, default: () => {} },
    secondaryBanner: { type: Object, default: () => {} },
    secondaryBannerLink: { type: Object, default: () => {} },
  },

  computed: {
    hasPrimaryBanner() {
      return this.primaryBanner.url && this.primaryBannerLink._meta;
    },
    showPrimaryBanner() {
      return this.childrenLength < 4 && this.hasPrimaryBanner;
    },
    hasSecondaryBanner() {
      return this.secondaryBanner.url && this.secondaryBannerLink._meta;
    },
    showSecondaryBanner() {
      return (
        this.hasSecondaryBanner &&
        (this.childrenLength < 3 ||
          (!this.hasPrimaryBanner && this.childrenLength < 4))
      );
    },
    childrenLength() {
      return Object.keys(this.children).length;
    },
  },
};
