
import { mapState } from "vuex";

export default {
  props: {
    primaryBanner: { type: Object, default: () => {} },
    primaryBannerLink: { type: Object, default: () => {} },
    secondaryBanner: { type: Object, default: () => {} },
    secondaryBannerLink: { type: Object, default: () => {} },
    children: { type: Object, default: () => {} },
    index: { type: Number, default: -1 },
  },

  data: () => ({ megaMenuItems: [] }),

  computed: {
    ...mapState("app", ["activeMenuIndex", "isMegaMenuOpen"]),
    showContainer() {
      return this.index == this.activeMenuIndex;
    },
    hideMegaMenu() {
      return !this.isMegaMenuOpen && this.activeMenuIndex == -1;
    },
  },

  methods: {
    closeMegaMenu() {
      if (this.activeMenuIndex >= 0) {
        this.$store.dispatch("app/closeMegaMenu");
      }
    },
  },
};
