
import { gql } from "graphql-tag";
import { mapState } from "vuex";

import { documentLink } from "~/app/prismic/prismic-fragments";

const MEGA_MENU_QUERY = gql`
  query header($uid: String!, $lang: String!) {
    header(uid: $uid, lang: $lang) {
      id: _meta {
        id
      }
      mega_menu_links {
        second_level_menu {
          ... on Second_level_menu {
            title
            primary_banner
            primary_banner_link {
              ...documentLink
            }
            secondary_banner
            secondary_banner_link {
              ...documentLink
            }
            body {
              ... on Second_level_menuBody2nd_level {
                type
                primary {
                  link_title
                  sub_title
                }
                fields {
                  second_level_link_text
                  second_level_link {
                    ...documentLink
                    ... on Collection_page {
                      _meta {
                        uid
                        type
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  ${documentLink}
`;

export default {
  data: () => ({ header: null }),

  async fetch() {
    const { header } = await this.$getPrismicData({
      query: MEGA_MENU_QUERY,
      variables: { uid: "site-header" },
    });

    this.header = header;
  },

  fetchOnServer: false,

  computed: {
    ...mapState("app", ["activeMenuIndex", "isMegaMenuOpen"]),
    rowsLimit() {
      return this.siteConfig?.megaMenu?.rowsLimit ?? 15;
    },
    megaMenuItems() {
      let results = [];
      const megaMenuLinks = this.header?.mega_menu_links ?? [];

      for (const menuLink of megaMenuLinks) {
        let children = {};
        const secondLevelMenu = menuLink?.second_level_menu;
        const l2Items = secondLevelMenu?.body ?? [];

        let colIndex = 0;
        let blockIndex = 0;
        let itemsInCol = 0;

        const resetVars = () => {
          itemsInCol = 0;
          blockIndex = 1;
          colIndex++;
          children[colIndex] = {};
        };

        for (let j = 0; j < l2Items.length; j++) {
          // a local function to insert L2 categories
          const setChildren = () => {
            l2Items[j].fields = l2Items[j].fields.filter(
              link => link.second_level_link_text && link.second_level_link
            );
            children[colIndex][blockIndex] = l2Items[j];
          };

          if (l2Items[j].primary.link_title) {
            resetVars();
            setChildren();

            // 2 + --> one for link title and for space below link title subtitle plus fields
            itemsInCol += 2 + l2Items[j].fields.length;
          } else {
            blockIndex++;

            // 1 + --> one for subtitle plus fields
            itemsInCol += 1 + l2Items[j].fields.length;

            // if rows exceeds 15 move items into next column
            if (itemsInCol > this.rowsLimit) {
              resetVars();
            }
            setChildren();
          }
        }

        results.push({
          primaryBanner: secondLevelMenu?.primary_banner ?? {},
          primaryBannerLink: secondLevelMenu?.primary_banner_link ?? {},
          secondaryBanner: secondLevelMenu?.secondary_banner ?? {},
          secondaryBannerLink: secondLevelMenu?.secondary_banner_link ?? {},
          children,
        });
      }

      return results;
    },
    // isOverlayShown() {
    //   if (
    //     this.activeMenuIndex >= 0 &&
    //     this.hierarchicalMenu[this.activeMenuIndex].children.length
    //   ) {
    //     return "block";
    //   }

    //   return "hidden";
    // },
  },
};
