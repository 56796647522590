import { render, staticRenderFns } from "./MegaMenuColumns.vue?vue&type=template&id=33d58385&scoped=true&"
import script from "./MegaMenuColumns.vue?vue&type=script&lang=js&"
export * from "./MegaMenuColumns.vue?vue&type=script&lang=js&"
import style0 from "./MegaMenuColumns.vue?vue&type=style&index=0&id=33d58385&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33d58385",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MegaMenuColumn: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/mega-menu/MegaMenuColumn.vue').default,MegaMenuBanner: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/mega-menu/MegaMenuBanner.vue').default})
