
import { path } from "ramda";

export default {
  props: {
    image: { type: Object, default: () => {} },
    link: { type: Object, default: () => {} },
  },

  computed: {
    imgSrc() {
      return path(["url"], this.image);
    },
    showBanner() {
      return this.imgSrc && this.link._meta;
    },
    imgProps() {
      return {
        src: this.imgSrc,
        alt: path(["alt"], this.image),
        width: 288,
        height: 460,
      };
    },
  },

  methods: {
    closeMegaMenu() {
      this.$store.dispatch("app/closeMegaMenu");
    },
  },
};
